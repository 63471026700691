



































































import Vue from 'vue';
import AppReport from '@/components/AppReport.vue';
import AppForm from '@/components/AppForm.vue';
import FieldDate from '@/components/FieldDate.vue';
import AppLoader from '@/components/AppLoader.vue';
import REPORT_DETENTION from '@/graphql/queries/ReportDetention.graphql';
import REPORT_DETENTION_EXPORT from '@/graphql/queries/ReportDetentionExport.graphql';
import { required } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import formatDate from '@/utils/formatDate';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import {
  ReportDetentionQuery,
  ReportDetentionExportQuery,
  ReportDetentionQueryVariables,
} from '@/types/schema';
import { ApolloQueryResult } from 'apollo-client';

export default Vue.extend({
  name: 'AdminReportsDetention',
  components: {
    AppReport,
    AppForm,
    FieldDate,
    AppLoader,
  },
  data() {
    return {
      form: {
        startDate: '',
        endDate: '',
      },
      reportDetention: [] as ReportDetentionQuery['reportDetention'],
    };
  },
  validations() {
    return {
      form: {
        startDate: { required },
        endDate: {
          required,
          afterTime: (value, { startDate }) =>
            !startDate || isAfter(parseISO(value), parseISO(startDate)),
        },
      },
    };
  },
  methods: {
    formatDate,
    validationMessages,
    reset() {
      this.$router.push({
        name: this.$route.name ?? '',
        query: {},
      });
    },
    async runReport() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$router.push({
        name: this.$route.name ?? '',
        query: { ...this.form },
      });
    },
    async exportReport() {
      const { startDate, endDate } = this.$route.query;
      type ExportResult = ApolloQueryResult<ReportDetentionExportQuery>;
      const response: ExportResult = await this.$apollo.query({
        query: REPORT_DETENTION_EXPORT,
        variables: { startDate, endDate },
        fetchPolicy: 'network-only',
      });
      const { reportDetentionExport } = response.data;
      if (reportDetentionExport && reportDetentionExport.url) {
        window.open(reportDetentionExport.url);
      }
    },
  },
  apollo: {
    reportDetention: {
      query: REPORT_DETENTION,
      variables(): ReportDetentionQueryVariables {
        const { startDate: start, endDate: end } = this.$route.query;
        const startDate = Array.isArray(start) ? '' : start;
        const endDate = Array.isArray(end) ? '' : end;
        return { startDate, endDate };
      },
      skip(): boolean {
        const { startDate, endDate } = this.$route.query;
        return !startDate || !endDate;
      },
    },
  },
});
